body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h3.cg-signup {
    font-weight:bold;
    font-size: 1.5rem;
}
h3.cg-signup a {
    text-decoration: underline;;
}
@tailwind base;
@tailwind components;
@tailwind utilities;